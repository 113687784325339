import React,{Component} from 'react';
import _ from 'lodash';
import './App.css';
import {data} from './Data.js'; 
import {Camera3D} from './Camera3D.js';
import {Model3D} from './Model3D.js';
import {Vector3D} from './Vector3D.js';
import Image2D from './Image2D.js';

const INTERVAL = 60;

class App extends Component {
  state = {
    code: null,
  };

  constructor(props) {
    super(props);
    this.loadData();
    window.addEventListener("keydown",(e)=> {
      if (this.intervalId === null) {
        this.setState({code:e.code});
        this.onKey(e.code);
        this.mainLoop();
      }
    });
    window.addEventListener("keyup",()=> {
      this.setState({code:null});
    });
  }

  loadData() {
    let rnd = ~~(Math.random()*data.image.length);
    this.camAt = new Vector3D(data.image[rnd].pos[0],data.image[rnd].pos[1]+70,data.image[rnd].pos[2]);
    this.camera = new Camera3D(new Vector3D(0,0,1000),this.camAt);
    this.preCam = this.camAt;
    this.current = -1;
    this.count = 0.0;
    this.intervalId = null;
    this.model = [];
    let num = 0;
    data.image.forEach( function(img) {
      this.model.push(new Model3D(img.img,img.pos,img.size,num));
      num++;
    }.bind(this));
   }

  resize = _.debounce(() => {
    this.camera.perspective();
    this.mainLoop();
    this.setState({code:null});
  });

  componentDidMount() {
    window.addEventListener('resize',this.resize);
    this.mainLoop();
    this.setState({code:null});
  }

  componentWillUnmount() {
    window.removeEventListener('resize',this.resize);
    window.removeEventListener('keydown',this.resize);
    window.removeEventListener('keyup',this.resize);
    window.removeEventListener('mouseup',this.resize);
    if (this.intervalId !== null)
      clearInterval(this.intervalId);
  }

  onUp(e,current) {
    if ('touchend' in document) return;
    this.onTouch(e,current);
  }

  onTouch(e,current) {
    if (this.intervalId !== null
    || this.current === current)
      return;
    this.current = current;
    let x = 100;
    let y = 70;
    let z = 100;
    for (let i = 0; i < this.model.length; i++) {
      if (this.model[i].current === current)
      {
        let pos = this.model[i].getPos();
        x = pos.x;
        y = pos.y + 70;
        z = pos.z;
        break;
      }
    }
    this.camAt = new Vector3D(x,y,z);
    this.count = 0;
    if (this.intervalId != null)
      clearInterval(this.intervalId);
    this.intervalId = setInterval(() => {
      this.cameraMove();
      this.mainLoop();
      this.setState({code:this.state.code});
    },INTERVAL);
  }

  cameraMove() {
    const p1 = this.camAt;
    const p2 = this.preCam;
    const p3 = this.camAt;
    const p4 = this.camAt;
    this.camera.interpolate(this.count,p1,p2,p3,p4);
    this.count += INTERVAL/1000;
    if (this.count > 1) {
      this.count = 1;
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.preCam = this.camAt;
    }
  }

  mainLoop() {
    this.camera.lookAt();
    for (let model of this.model) {
      model.getPosition(this.camera);
      model.getHeight(this.camera);
      model.centering();
    }
  }

  compareFunc(a, b) {
    return b.loc.z - a.loc.z;
  }

  render() {
    let array = [];
    this.model.sort(this.compareFunc);
    this.model.forEach((elm) => {
      array.push(<Image2D parent={this} model={elm} />);
    });
    if (this.intervalId == null) {
      array.push(<h1>@VIXAR</h1>);
      switch ( this.current ) {
        case 0: array.push(<h2><a href="https://paradox.vexil.jp">PARADOX</a></h2>); break;
        case 1: array.push(<h2>Empty</h2>); break;
        case 2: array.push(<h2>Empty</h2>); break;
        case 3: array.push(<h2>Empty</h2>); break;
        case 4: array.push(<h2>Empty</h2>); break;
        case 5: array.push(<h2><a href="https://cco24.com">カーコンサルタント大西</a></h2>); break;
        case 6: array.push(<h2>Empty</h2>); break;
        case 7: array.push(<h2>Empty</h2>); break;
        case 8: array.push(<h2>Empty</h2>); break;
        case 9: array.push(<h2>Empty</h2>); break;
        case 10: array.push(<h2>Empty</h2>); break;
        case 11: array.push(<h2>Empty</h2>); break;
        case 12: array.push(<h2>Empty</h2>); break;
        case 13: array.push(<h2>Empty</h2>); break;
        case 14: array.push(<h2>Empty</h2>); break;
        case 15: array.push(<h2>Empty</h2>); break;
        case 16: array.push(<h2>Empty</h2>); break;
        case 17: array.push(<h2>Empty</h2>); break;
        case 18: array.push(<h2>Empty</h2>); break;
        case 19: array.push(<h2>Empty</h2>); break;
        case 20: array.push(<h2>Empty</h2>); break;
        case 21: array.push(<h2>Empty</h2>); break;
        case 22: array.push(<h2>Empty</h2>); break;
        case 23: array.push(<h2>Empty</h2>); break;
        case 24: array.push(<h2>Empty</h2>); break;
        case 25: array.push(<h2>Empty</h2>); break;
        case 26: array.push(<h2>Empty</h2>); break;
        case 27: array.push(<h2>Empty</h2>); break;
        case 28: array.push(<h2>Empty</h2>); break;
        case 29: array.push(<h2>Empty</h2>); break;
        case 30: array.push(<h2><a href="https://profile.vixar.jp">大西武のプロフィール</a></h2>); break;
        case 31: array.push(<h2>Empty</h2>); break;
        case 32: array.push(<h2>Empty</h2>); break;
        case 33: array.push(<h2>Empty</h2>); break;
        case 34: array.push(<h2>Empty</h2>); break;
        case 35: array.push(<h2><a href="https://common.vexil.jp">共通点を探せ！</a></h2>); break;
        default: array.push(<h2><a href="https://twitter.com/Roxiga">広告画像とリンクを1万円で載せます！</a></h2>); break;
       }
    }
    return <div className="App">
      {array}
    </div>
  }
}

export default App;
