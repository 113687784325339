export class Vector2D {
  constructor(x,y) {
	this.x = x;
	this.y = y;
  }
};

export class Vector3D {
  constructor(x,y,z) {
	this.x = x;
	this.y = y;
	this.z = z;
  }
  length() {
	return Math.sqrt(
	  this.x*this.x + this.y*this.y + this.z*this.z);
  }
  subtract(v) {
	let v2 = new Vector3D(0,0,0);
	v2.x = this.x - v.x;
	v2.y = this.y - v.y;
	v2.z = this.z - v.z;
	return v2;
  }
  normalize() {
	const l = this.length();
	if ( l === 0 ) {
	  this.x = 0;
	  this.y = 0;
	  this.z = 1;
	} else {
	  this.x /= l;
	  this.y /= l;
	  this.z /= l;
	}
  }
  dotProduct(v) {
  	return (v.x*this.x + v.y*this.y + v.z*this.z);
  }
  crossProduct(v) {
  	const x = (this.y*v.z) - (this.z*v.y);
  	const y = (this.z*v.x) - (this.x*v.z);
  	const z = (this.x*v.y) - (this.y*v.x);
  	return new Vector3D(x,y,z);
  }
  copy() {
    return new Vector3D(this.x,this.y,this.z);
  }
  toString() {
  	return "x="+this.x+",y="+this.y+",z="+this.z;	
  }
};

export class Vector4D {
  constructor(x,y,z,w) {
	this.x = x;
	this.y = y;
	this.z = z;
	this.w = w;
  }
};
