import {Vector4D} from './Vector3D.js';

export class Matrix3D {
  constructor(e) {
    if ( e ) {
      this._e = e;
    } else {
	  this.identity();
	}
  }
  identity() {
	this._e = [
  	  1,0,0,0,
	  0,1,0,0,
	  0,0,1,0,
	  0,0,0,1];
  }
  transform(v) {
    const x = v.x * this._e[0] + v.y * this._e[4] +
	  v.z * this._e[8]  + v.w * this._e[12];
    const y = v.x * this._e[1] + v.y * this._e[5] +
	  v.z * this._e[9]  + v.w * this._e[13];
    const z = v.x * this._e[2] + v.y * this._e[6] +
	  v.z * this._e[10] + v.w * this._e[14];
    const w = v.x * this._e[3] + v.y * this._e[7] +
	  v.z * this._e[11] + v.w * this._e[15];
    return new Vector4D(x,y,z,w);
  }
  lookAt(eye, at, up) {
	let z2 = eye.subtract(at);
	z2.normalize();
	let x2 = up.crossProduct(z2);
	x2.normalize();
	let y2 = z2.crossProduct(x2);
	this._e = [
	  x2.x,y2.x,z2.x,0,
	  x2.y,y2.y,z2.y,0,
	  x2.z,y2.z,z2.z,0,
	  -x2.dotProduct(eye),
	  -y2.dotProduct(eye),
	  -z2.dotProduct(eye),
	1];
  }
  frustum(left, right, bottom, top, near, far) {
	const rl = (right - left);
	const tb = (top - bottom);
	const fn = (far - near);
	this._e[ 0] = (near * 2) / rl;
	this._e[ 1] = 0;
	this._e[ 2] = 0;
	this._e[ 3] = 0;
	this._e[ 4] = 0;
	this._e[ 5] = (near * 2) / tb;
	this._e[ 6] = 0;
	this._e[ 7] = 0;
	this._e[ 8] = (right + left) / rl;
	this._e[ 9] = (top + bottom) / tb;
	this._e[10] = -(far + near) / fn;
	this._e[11] = -1;
	this._e[12] = 0;
	this._e[13] = 0;
	this._e[14] = -(far * near * 2) / fn;
	this._e[15] = 0;
  }
  perspective(fov, aspect, near, far) {
    const top = near * Math.tan(fov*Math.PI/360.0);
	const right = top * aspect;
	this.frustum(-right,right,-top,top,near,far);
  }
};
