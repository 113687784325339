import { Vector2D, Vector3D, Vector4D } from "./Vector3D";

export class Model3D {
  loc = new Vector3D(0,0,0);
  height = 1000;
  constructor(image,pos,size,num) {
    this.image = image;
	  this.pos = new Vector3D(pos[0],pos[1],pos[2]);
    this.size = new Vector2D(size[0],size[1]);
    this.current = num;
  }
  getPosition(camera) {
    const v  = new Vector4D(
      this.pos.x,this.pos.y,this.pos.z,1);
    const v2 = camera.mvMatrix.transform(v);
    const v3 = camera.pMatrix.transform(v2);
    const w = window.innerWidth/2;
    const h = window.innerHeight/2;
    const half = new Vector2D(w,h);
    const x = (v3.x / v3.w * half.x) + half.x;
    const y = -(v3.y / v3.w * half.y) + half.y;
    this.loc = new Vector3D(x,y,v3.z);
  }
  getHeight(camera) {
    const dist = this.getDistance(camera);
    const dist2 = camera.getScreenDistance();
    if (this.loc.z <= 0 )
      this.height = 0;
    else
      this.height = (this.size.y*dist2)/dist;
  }  
  getDistance(camera) {
    const x = this.pos.x - camera.eye.x;
    const y = this.pos.y - camera.eye.y;
    const z = this.pos.z - camera.eye.z;
    return Math.sqrt(x*x + y*y + z*z);
  }
  centering() {
    const w = this.size.x*this.height/this.size.y;
    const x = this.loc.x - w/2.0;
    const y = this.loc.y - this.height;
    this.loc = new Vector3D(x,y,this.loc.z); 
  }
  getPos() {
    return new Vector3D(this.pos.x,this.pos.y,this.pos.z);
  }
  toString() {
  	return "x="+this.pos.x+",y="+
      this.pos.y+",z="+this.pos.z;
  }
};
