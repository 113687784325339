import vocal from './images/vocal.png';
import guitar from './images/guitar.png';
import bass from './images/bass.png';
import drum from './images/drum.png';
import empty from './images/empty.png';

export const data = {
  image : [
    {img:drum,size:[290,265],pos:[125,0,125]},
    {img:empty,size:[210,265],pos:[75,0,125]},
    {img:empty,size:[210,265],pos:[25,0,125]},
    {img:empty,size:[210,265],pos:[-25,0,125]},
    {img:empty,size:[210,265],pos:[-75,0,125]},
    {img:vocal,size:[80,265],pos:[-125,0,125]},
    {img:empty,size:[210,265],pos:[125,0,75]},
    {img:empty,size:[210,265],pos:[75,0,75]},
    {img:empty,size:[210,265],pos:[25,0,75]},
    {img:empty,size:[210,265],pos:[-25,0,75]},
    {img:empty,size:[210,265],pos:[-75,0,75]},
    {img:empty,size:[210,265],pos:[-125,0,75]},
    {img:empty,size:[210,265],pos:[125,0,25]},
    {img:empty,size:[210,265],pos:[75,0,25]},
    {img:empty,size:[210,265],pos:[25,0,25]},
    {img:empty,size:[210,265],pos:[-25,0,25]},
    {img:empty,size:[210,265],pos:[-75,0,25]},
    {img:empty,size:[210,265],pos:[-125,0,25]},
    {img:empty,size:[210,265],pos:[125,0,-25]},
    {img:empty,size:[210,265],pos:[75,0,-25]},
    {img:empty,size:[210,265],pos:[25,0,-25]},
    {img:empty,size:[210,265],pos:[-25,0,-25]},
    {img:empty,size:[210,265],pos:[-75,0,-25]},
    {img:empty,size:[210,265],pos:[-125,0,-25]},
    {img:empty,size:[210,265],pos:[125,0,-75]},
    {img:empty,size:[210,265],pos:[75,0,-75]},
    {img:empty,size:[210,265],pos:[25,0,-75]},
    {img:empty,size:[210,265],pos:[-25,0,-75]},
    {img:empty,size:[210,265],pos:[-75,0,-75]},
    {img:empty,size:[210,265],pos:[-125,0,-75]},
    {img:guitar,size:[210,265],pos:[125,0,-125]},
    {img:empty,size:[210,265],pos:[75,0,-125]},
    {img:empty,size:[210,265],pos:[25,0,-125]},
    {img:empty,size:[210,265],pos:[-25,0,-125]},
    {img:empty,size:[210,265],pos:[-75,0,-125]},
    {img:bass,size:[155,265],pos:[-125,0,-125]},
  ],
}
