import "./App.css";

const Image2D = ({parent,model}) => {
  const style = {
    left: model.loc.x+"px",
    top: model.loc.y+"px",
  }
  const cur = model.current;
  const onUp = (e) => parent.onUp(e,cur);
  const onTouch = (e) => parent.onTouch(e,cur);
  return <div className="image2d" style={style}
    onMouseUp={onUp} onTouchEnd={onTouch}>
    <img src={model.image}
      height={model.height} alt="画像" />
  </div>
};

export default Image2D;
