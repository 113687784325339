import { Vector3D } from "./Vector3D";
import { Matrix3D } from "./Matrix3D";

export class Camera3D {
  mvMatrix = new Matrix3D();
  pMatrix = new Matrix3D();
  fov = 45.0;
  near = 1.0;
  far = 8192.0;
  up = new Vector3D(0,1,0);
  constructor(eye,at) {
	  this.eye = eye.copy();
    this.at = at;
    this.perspective();
  }
  perspective() {
    this.aspect = window.innerWidth/window.innerHeight;
    this.pMatrix.perspective(
      this.fov,this.aspect,this.near,this.far);
  }
  toRadian(degree) {
    return degree * Math.PI / 180;
  }
  toDegree(radian) {
    return radian * 180 / Math.PI;
  }
  getScreenDistance() {
    const t = 2/Math.tan(this.toRadian(this.fov/2));
    return window.innerHeight/t;
  }
  lookAt() {
    this.eye.x = this.at.x * 2;
    this.eye.y = this.at.y + 50;
    this.eye.z = this.at.z * 2;
    this.mvMatrix.lookAt(this.eye,this.at,this.up);
  }

  interpolate(t,p1,p2,p3,p4) {
    const x1 = p1.x;
    const x2 = p2.x;
    const x3 = p3.x;
    const x4 = p4.x;
    const x=this.spline(t,x1,x2,x3,x4);
    const y1 = p1.y;
    const y2 = p2.y;
    const y3 = p3.y;
    const y4 = p4.y;
    const y=this.spline(t,y1,y2,y3,y4);
    const z1 = p1.z;
    const z2 = p2.z;
    const z3 = p3.z;
    const z4 = p4.z;
    const z=this.spline(t,z1,z2,z3,z4);
    this.at = new Vector3D(x,y,z);
  }
  spline(t,p1,p2,p3,p4) {
    const t2 = t * t;
    const t3 = t2 * t;
    const alpha = 0.0;
    const val1 = ((1 - alpha) / 2.0) * 
      ((p2 - p1) + p3 - p2);
    const val2 = ((1 - alpha) / 2.0) * 
      ((p3 - p2) + p4 - p3);
  
    return (((2 * t3) - (3 * t2) + 1) * p2) +
      ((t3 - (2 * t2) + t) * val1) +
      ((t3 - t2) * val2) +
      (((-2 * t3) + (3 * t2)) * p3);
  }
};
